#hubSyncSummary {
  width: 75%;
  margin: 0 auto;
}

#hubSyncSummary .card-text {
  font-size: small;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.center {
  text-align: center;
}

.hub-table {
  width: 75%;
  margin: 0 auto;
  overflow: auto;
  height: 225px;
}
