#logoText {
  font-weight: bold;

  display: inline-block;
}

#appLogo {
  display: inline-block;
}

#logo {
  text-align: center;
}

#header {
  margin-top: 50px;
  margin-bottom: 50px;
}

.nav-link {
  color: black;
}
